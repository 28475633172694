


































import { Component, Vue } from 'vue-property-decorator'
import breakpoints from '@/plugins/breakpoints'

import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import Menu from '@/layouts/components/Menu.vue'
import Toggler from '@/layouts/components/Toggler.vue'
import { UserModel } from '@/modules/ProfilePage/models/UserModel'

import { namespace } from 'vuex-class'
const UserStore = namespace('UserStore')

@Component({
  name: 'Drawer',
  components: { BaseButton, BaseIcon, Menu, Toggler },
})
export default class Drawer extends Vue {
  openMobileMenu = false
  breakpoints = breakpoints

  @UserStore.Getter
  public currentUser!: UserModel

  get isSmall(): boolean {
    return breakpoints.width <= 1366
  }
}
