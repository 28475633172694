










import { Component, Emit, Vue } from 'vue-property-decorator'

@Component({ name: 'Toggler' })
export default class Toggler extends Vue {
  toggle = false

  @Emit('toggler')
  toggleMenu(): boolean {
    this.toggle = !this.toggle
    return this.toggle
  }
}
